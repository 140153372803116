import { Box, Flex, Stack } from '@chakra-ui/react'
import { BiCog, BiHome } from 'react-icons/bi'
import * as React from 'react'
import { AccountSwitcher } from '@features/organisations/components/AccountSwitcher'
import { OrgId } from '@features/organisations/types'
import useMenuControl from '@features/layout/hooks/useMenuControl'
import { LogoutWrapper } from '@features/auth/components/LogoutWrapper'
import { FaArrowRightFromBracket } from 'react-icons/fa6'
import { NavItem } from './Sidebar/NavItem'
import { NavGroup } from './Sidebar/NavGroup'
import { OrgRoutesNavGroup } from './OrgRoutesNavGroup'

interface Props {
  orgId: OrgId
}

const UserNavigation: React.FC<Props> = ({ orgId }) => {
  const { isFull } = useMenuControl()

  return (
    <Flex as="nav" left="0" pb="5" px={2} color="gray.200" h="full">
      <Flex direction="column" h="full" justify="space-between">
        <Box fontSize="sm" lineHeight="tall">
          <Box
            display="block"
            transition="background 0.1s"
            rounded="xl"
            _hover={{ bg: 'whiteAlpha.200' }}
            whiteSpace="nowrap"
            mb={2}
          >
            <AccountSwitcher />
          </Box>
          <Stack spacing="8" flex="1" overflow="auto">
            <NavItem
              icon={<BiHome />}
              label="Home"
              href="/"
              expanded={isFull}
            />

            {orgId !== null && (
              <OrgRoutesNavGroup orgId={orgId} label="Studio" isFull={isFull} />
            )}
          </Stack>
        </Box>

        <Box>
          <Stack flex="1" overflow="auto">
            <NavGroup label="">
              <NavItem
                expanded={isFull}
                subtle
                icon={<BiCog />}
                label="Settings"
                href="/settings"
              />
              <LogoutWrapper>
                <NavItem
                  subtle
                  icon={<FaArrowRightFromBracket />}
                  label="Sign Out"
                  expanded={isFull}
                />
              </LogoutWrapper>
            </NavGroup>
          </Stack>
        </Box>
      </Flex>
    </Flex>
  )
}

export default UserNavigation
