import { useAppDispatch, useAppSelector } from '@lib/rtk/hooks'
import {
  MenuSize,
  selectMenuHidden,
  selectMenuSize,
  toggleMenuSize,
  toggleMenuVisibility,
} from '@features/layout/slice'

const useMenuControl = () => {
  const dispatch = useAppDispatch()

  const isHidden = useAppSelector(selectMenuHidden)
  const menuSize = useAppSelector(selectMenuSize)

  const toggleVisibility = () => {
    dispatch(toggleMenuVisibility())
  }

  const toggleSize = () => {
    dispatch(toggleMenuSize())
  }

  return {
    isHidden,
    isFull: menuSize === MenuSize.full,
    toggleVisibility,
    toggleSize,
  }
}

export default useMenuControl
