import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import Link from 'next/link'
import { useUser } from '@lib/firebase/provider'
import { useSelectedOrgContext } from '@features/organisations/contexts/ManageSelectedOrg'
import { FaArrowRightFromBracket } from 'react-icons/fa6'
import { useGetOrgQuery } from '@features/organisations'
import { skipToken } from '@reduxjs/toolkit/query'
import { LogoutWrapper } from '../../auth/components/LogoutWrapper'
import { AccountSwitcherButton } from './AccountSwitcherButton'

export const AccountSwitcher = () => {
  const { user, isInitialized } = useUser()
  const { orgId } = useSelectedOrgContext()

  const { data: organisation } = useGetOrgQuery(orgId ?? skipToken, {
    skip: !user?.id || !isInitialized || !orgId,
  })

  return (
    <Menu>
      <AccountSwitcherButton />
      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue('gray.600', 'gray.200')}
        px="3"
      >
        <Text maxWidth={'160px'} noOfLines={1} fontWeight="semibold">
          {organisation?.displayName ?? 'No organisation selected'}
        </Text>
        <Text fontWeight="light" mb="2">
          {user?.email}
        </Text>
        <MenuDivider />
        <Link href="/o">
          <MenuItem rounded="md">Switch Organization</MenuItem>
        </Link>
        <Link href="/orgOverview">
          <MenuItem rounded="md">Manage Organizations</MenuItem>
        </Link>
        <MenuDivider />
        <LogoutWrapper>
          <MenuItem rounded="md" icon={<FaArrowRightFromBracket />}>
            Sign Out
          </MenuItem>
        </LogoutWrapper>
      </MenuList>
    </Menu>
  )
}
