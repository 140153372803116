import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { useNetworkState } from '@uidotdev/usehooks'
import { FC, ReactNode } from 'react'

import useIsStaffUser from '@hooks/useIsStaffUser'
import { StaffNavigation } from '@components/Navigation/StaffNavigation'
import { useSelectedOrgContext } from '@features/organisations/contexts/ManageSelectedOrg'

import useMenuControl from '@features/layout/hooks/useMenuControl'
import { CloudmixIcon } from '@components/Icons/ProviderIcons'
import * as React from 'react'
import {
  MenuHiddenButton,
  MenuSizeButton,
} from '@features/layout/components/MenuControlButtons'
import UserNavigation from '../Navigation/UserNavigation'

export interface BasePageProps {
  title?: string
  breadcrumbs?: ReactNode
  children?: ReactNode
}

const BasePage: FC<BasePageProps> = ({ title, breadcrumbs, children }) => {
  const { isFull, isHidden } = useMenuControl()
  const network = useNetworkState()
  const isOnline = network?.online

  // TODO: Is too slow? AccountSwitcherButton is still initialising before isStaff is returning true
  const isStaff = useIsStaffUser()
  const { orgId } = useSelectedOrgContext()

  return (
    <Flex
      height="100vh"
      bg={mode('gray.900', 'gray.900')}
      overflow={'auto'}
      sx={{ '--sidebar-width': '14rem' }}
    >
      {!isHidden && (
        <Flex
          h={'full'}
          direction={'column'}
          {...(isFull && { width: 'var(--sidebar-width)' })}
          align={'stretch'}
        >
          <Flex align={'center'} justify={'center'} h={14}>
            {isFull ? (
              <Image
                src="https://control.cloudmix.tv/assets/images/spin-logo-inverted-@2X.png"
                alt="Cloudmix Logo"
                px={2}
                pt={1}
                w={'12rem'}
              />
            ) : (
              <Icon as={CloudmixIcon} boxSize={14} />
            )}
          </Flex>
          <Flex justify={'center'} gap={2} mt={2} mb={1}>
            <MenuHiddenButton />
            <MenuSizeButton />
          </Flex>
          {isStaff === true ? (
            <StaffNavigation orgId={orgId as string} />
          ) : (
            <UserNavigation orgId={orgId as string} />
          )}
        </Flex>
      )}
      <Box flex="1" position="relative">
        <Box bg={'bg-workspace'} height="100%" pb="2" rounded={'sm'}>
          {!isOnline && (
            <Alert
              status="warning"
              position={'absolute'}
              top={0}
              justifyContent={'center'}
            >
              <AlertIcon />
              Network Offline: There may be issues with your internet
              connection.
            </Alert>
          )}
          <Flex direction="column" height="full">
            <Flex
              w="full"
              py="3"
              justify="space-between"
              align="center"
              px={{ base: 2, lg: 4 }}
            >
              <Flex align="center" minH="8" w="full">
                {isHidden && <MenuHiddenButton mr={2} />}
                {title && (
                  <Heading
                    size="md"
                    fontWeight="extrabold"
                    textAlign={{ base: 'right', lg: 'left' }}
                    w={'100%'}
                    color={mode('gray.700', 'gray.200')}
                  >
                    {title}
                  </Heading>
                )}
              </Flex>
              {breadcrumbs}
            </Flex>
            <Flex
              direction="column"
              flex="1"
              overflowY="auto"
              px={3}
              pt={2}
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'white',
                  borderRadius: '24px',
                },
              }}
            >
              <Box flex="1" h={'100%'}>
                {children}
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

export default BasePage
