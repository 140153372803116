import {
  Avatar,
  Box,
  Flex,
  FlexProps,
  HStack,
  Text,
  useMenuButton,
} from '@chakra-ui/react'
import { HiSelector } from 'react-icons/hi'
import { useUser } from 'src/lib/firebase/provider'
import { useSelectedOrgContext } from '@features/organisations/contexts/ManageSelectedOrg'
import useMenuControl from '@features/layout/hooks/useMenuControl'
import { skipToken } from '@reduxjs/toolkit/query'
import { useGetOrgQuery } from '../api'

export const AccountSwitcherButton = (props: FlexProps) => {
  const buttonProps = useMenuButton(props)
  const { user, isInitialized } = useUser()
  const { isFull } = useMenuControl()

  const { orgId } = useSelectedOrgContext()

  const { data: organisation } = useGetOrgQuery(orgId ?? skipToken, {
    skip: !user?.id || !isInitialized || !orgId,
  })

  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg="gray.700"
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: 'gray.600' }}
      _focus={{ shadow: 'outline' }}
    >
      <HStack flex="1" spacing="3">
        <Avatar
          name={user?.displayName ?? undefined}
          size={'sm'}
          src={user?.photoURL ?? undefined}
        />
        {isFull && (
          <Box textAlign="start">
            <Text w={'7.6rem'} noOfLines={1} fontWeight="semibold">
              {organisation?.displayName ?? 'No organisation selected'}
            </Text>
            {user?.displayName && (
              <Text w={'7.6rem'} noOfLines={1} fontSize="xs" color="gray.400">
                {user?.displayName}
              </Text>
            )}
          </Box>
        )}
      </HStack>
      <Box fontSize="lg" color="gray.400">
        <HiSelector />
      </Box>
    </Flex>
  )
}
