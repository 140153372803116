import { Box } from '@chakra-ui/react'
import { persistor, SIGNOUT_REQUEST } from '@lib/store'
import { useUser } from '@lib/firebase/provider'
import useAppDispatch from '@hooks/useAppDispatch'

export const LogoutWrapper = ({ children }) => {
  const dispatch = useAppDispatch()
  const { logout } = useUser()

  const handleClick = async () => {
    await logout()
    dispatch(SIGNOUT_REQUEST)
    await persistor.purge()
    await persistor.flush()
  }

  return <Box onClick={() => handleClick()}>{children}</Box>
}
