import { Box, HStack, Tooltip } from '@chakra-ui/react'
import * as React from 'react'
import { BsCaretRightFill } from 'react-icons/bs'
import { useRouter } from 'next/router'
import Link from 'next/link'

interface NavItemProps {
  href?: string
  label: string
  subtle?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  active?: boolean
  icon: React.ReactElement
  endElement?: React.ReactElement
  children?: React.ReactNode
  expanded?: boolean
}

export const NavItem = (props: NavItemProps) => {
  const { subtle, icon, children, label, endElement, href, expanded } = props
  const router = useRouter()
  const isActive = router.asPath.split('?')[0] === href

  return (
    <Link href={href ?? '/'}>
      <Tooltip
        label={label}
        hasArrow
        placement={'right'}
        isDisabled={expanded}
        fontSize={'md'}
        px={4}
        py={1}
      >
        <HStack
          w="full"
          px="3"
          py="2"
          cursor="pointer"
          userSelect="none"
          rounded="md"
          transition="all 0.2s"
          bg={isActive ? 'gray.700' : undefined}
          _hover={{ bg: 'gray.700' }}
          _active={{ bg: 'gray.600' }}
          justify={expanded ? 'start' : 'center'}
        >
          <Box fontSize="lg" color={isActive ? 'currentcolor' : 'gray.400'}>
            {icon}
          </Box>
          {expanded && (
            <>
              <Box
                flex="1"
                fontWeight="inherit"
                color={subtle ? 'gray.400' : undefined}
              >
                {label}
              </Box>
              {endElement && !children && <Box>{endElement}</Box>}
              {children && (
                <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />
              )}
            </>
          )}
        </HStack>
      </Tooltip>
    </Link>
  )
}
