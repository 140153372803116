import useMenuControl from '@features/layout/hooks/useMenuControl'
import { IconButton, IconButtonProps } from '@chakra-ui/react'
import { FaBars, FaMinus, FaPlus, FaXmark } from 'react-icons/fa6'
import * as React from 'react'

type MenuButtonProps = Omit<IconButtonProps, 'aria-label'>

const MenuHiddenButton = (props: MenuButtonProps) => {
  const { toggleVisibility, isHidden } = useMenuControl()
  return (
    <IconButton
      icon={!isHidden ? <FaXmark /> : <FaBars />}
      onClick={toggleVisibility}
      variant={'ghost'}
      aria-label={isHidden ? 'Show Menu' : 'Hide Menu'}
      {...props}
    />
  )
}

const MenuSizeButton = (props: MenuButtonProps) => {
  const { toggleSize, isFull } = useMenuControl()
  return (
    <IconButton
      icon={isFull ? <FaMinus /> : <FaPlus />}
      onClick={toggleSize}
      variant={'ghost'}
      aria-label={isFull ? 'Collapse Menu' : 'Expand Menu'}
      {...props}
    />
  )
}
export { MenuSizeButton, MenuHiddenButton }
