import { Box, Flex, Stack, Heading } from '@chakra-ui/react'
import { BiBuoy, BiCog } from 'react-icons/bi'
import * as React from 'react'
import {
  FaLayerGroup,
  FaProjectDiagram,
  FaServer,
  FaUsers,
} from 'react-icons/fa'
import { LogoutWrapper } from '@features/auth/components/LogoutWrapper'
import { MdPhotoLibrary } from 'react-icons/md'
import { RiLiveLine } from 'react-icons/ri'
import { FC } from 'react'
import { OrgId } from '@features/organisations/types'
import { FaArrowRightFromBracket } from 'react-icons/fa6'
import useMenuControl from '@features/layout/hooks/useMenuControl'
import { useGetOrgQuery } from '@features/organisations'
import { skipToken } from '@reduxjs/toolkit/query'
import { NavItem } from './Sidebar/NavItem'
import { NavGroup } from './Sidebar/NavGroup'
import { OrgRoutesNavGroup } from './OrgRoutesNavGroup'

const tryAddOrgIdToPath = (path: string, orgId?: OrgId) =>
  orgId ? `${path}?orgId=${orgId}` : path

interface Props {
  orgId?: OrgId
}

export const StaffNavigation: FC<Props> = ({ orgId }) => {
  const { isFull } = useMenuControl()
  const { data } = useGetOrgQuery(orgId ?? skipToken, { skip: !orgId })

  const displayName = data?.displayName ?? 'No display name set'

  return (
    <Flex as="nav" left="0" pb="5" px={2} color="gray.200" h="full">
      <Flex
        direction="column"
        h="full"
        justify="space-between"
        {...(isFull && { width: 'var(--sidebar-width)' })}
      >
        <Box fontSize="sm" lineHeight="tall">
          <Flex
            mt={0}
            justify={'center'}
            align={'center'}
            bgGradient="linear(to-l, red.700, orange.500)"
            py={1}
          >
            <Heading size={isFull ? 'md' : 'xs'}>ADMIN</Heading>
          </Flex>
          <Stack spacing="8" flex="1" overflow="auto" pt="8">
            <NavGroup label="Stakeholders">
              <NavItem
                icon={<FaUsers />}
                label={'Users'}
                href={tryAddOrgIdToPath('/staff/u', orgId)}
                expanded={isFull}
              />
              <NavItem
                icon={<FaLayerGroup />}
                label={'Organisations'}
                href={`/staff/o`}
                expanded={isFull}
              />
            </NavGroup>

            <NavGroup label="Monitoring">
              <NavItem
                icon={<FaProjectDiagram />}
                label={'Sessions'}
                href={tryAddOrgIdToPath('/staff/sessions', orgId)}
                expanded={isFull}
              />
              <NavItem
                icon={<FaServer />}
                label={'Engines'}
                href={tryAddOrgIdToPath('/staff/engines', orgId)}
                expanded={isFull}
              />
            </NavGroup>

            {orgId && (
              <Stack>
                <OrgRoutesNavGroup
                  orgId={orgId}
                  label={isFull ? `Organization: ${displayName}` : displayName}
                  isFull={isFull}
                />
              </Stack>
            )}
          </Stack>
        </Box>

        <Box mt={3}>
          <Stack spacing="1">
            <NavItem
              subtle
              icon={<BiCog />}
              label="Settings"
              href={tryAddOrgIdToPath('/settings', orgId)}
              expanded={isFull}
            />
            <LogoutWrapper>
              <NavItem
                subtle
                icon={<FaArrowRightFromBracket />}
                label="Sign Out"
                expanded={isFull}
              />
            </LogoutWrapper>
          </Stack>
        </Box>
      </Flex>
    </Flex>
  )
}
