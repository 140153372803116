import { createIcon } from '@chakra-ui/react'

export const CloudmixIcon = createIcon({
  displayName: 'CloudmixIcon',
  viewBox: '675 0 4900 1800',
  path: [
    <path
      d="m1057.02 418.107 696.955 397.491-696.955 396.074V418.107Z"
      fill="#08a5e1"
    />,
    <path
      d="m1753.98 815.586-696.955-397.491 698.868-396.38-1.913 793.871Z"
      fill="#0b7aff"
    />,
    <path
      d="m2439.89 1214.52-685.902-398.931 686.209-396.617-.307 795.548Z"
      fill="#1a9bfc"
    />,
    <path
      d="m1755.88 21.722 684.321 397.231-686.234 396.64 1.913-793.871Z"
      fill="#0059a1"
    />,
    <path
      d="m3127.41 1614.24-687.509-399.71 689.162-395.838-1.653 795.548Z"
      fill="#1a9bfc"
    />,
    <path
      d="m2440.2 418.96 688.855 399.71-689.162 395.838.307-795.548Z"
      fill="#6bc1fd"
    />,
    <path
      d="m5197.81 425.683-695.349 395.413 695.349 400.135V425.683Z"
      fill="#0b7aff"
    />,
    <path
      d="m4502.46 821.098 695.349-395.413L4502.46 26.518v794.58Z"
      fill="#7fd8e2"
    />,
    <path
      d="m3816.7 1218.38 685.761-397.278-685.147-398.27-.614 795.548Z"
      fill="#4de8f7"
    />,
    <path
      d="M4502.46 26.506 3817.313 422.84l685.147 398.246V26.506Z"
      fill="#a0ecf9"
    />,
    <path
      d="m3127.41 1614.24 689.28-395.861-687.627-399.687-1.653 795.548Z"
      fill="#74dff7"
    />,
    <path
      d="m3817.32 422.835-688.264 395.838 687.65 399.71.614-795.548Z"
      fill="#a0ecf9"
    />,
    <path
      d="m1770.42 1842.7-694.664-398.719 696.978-396.074-2.314 794.793ZM4502.46 1842.7l695.349-396.381-695.349-400.136V1842.7Z"
      fill="#0059a1"
    />,
  ],
})

export const GoogleIcon = createIcon({
  displayName: 'GoogleIcon',
  path: (
    <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
      <path
        fill="#4285F4"
        d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
      />
      <path
        fill="#34A853"
        d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
      />
      <path
        fill="#FBBC05"
        d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
      />
      <path
        fill="#EA4335"
        d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
      />
    </g>
  ),
})

export const GitHubIcon = createIcon({
  displayName: 'GitHubIcon',
  path: (
    <path
      fill="currentColor"
      d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
    />
  ),
})

export const TwitterIcon = createIcon({
  displayName: 'TwitterIcon',
  path: (
    <path
      fill="#03A9F4"
      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
    />
  ),
})
