import { OrgId } from '@features/organisations/types'
import { FC } from 'react'

import {
  FaDiagramProject,
  FaChartLine,
  FaRightToBracket,
  FaRightFromBracket,
} from 'react-icons/fa6'
import { RiLiveLine } from 'react-icons/ri'
import { NavGroup } from './Sidebar/NavGroup'
import { NavItem } from './Sidebar/NavItem'

interface Props {
  orgId: OrgId
  label: string
  isFull: boolean
}

export const OrgRoutesNavGroup: FC<Props> = ({ orgId, label, isFull }) => (
  <NavGroup label={label}>
    <NavItem
      expanded={isFull}
      icon={<FaDiagramProject />}
      label="Projects"
      href={`/o/${orgId}/projects`}
    />
    <NavItem
      expanded={isFull}
      icon={<FaRightToBracket />}
      label="Live Inputs"
      href={`/o/${orgId}/liveInput`}
    />
    <NavItem
      expanded={isFull}
      icon={<FaRightFromBracket />}
      label="Live Outputs"
      href={`/o/${orgId}/liveOutput`}
    />
    {/* <NavItem */}
    {/*  expanded={isFull} */}
    {/*  icon={<FaWindowRestore />} */}
    {/*  label="Commentary Box" */}
    {/*  href={`/o/${orgId}/commentaryBox`} */}
    {/* /> */}
    {/* TODO: restore this route once media library is finished */}
    {/* <NavItem */}
    {/*  expanded={isFull} */}
    {/*  icon={<FaFolderTree />} */}
    {/*  label="Media Library" */}
    {/*  href={`/o/${orgId}/mediaLibrary`} */}
    {/* /> */}
    <NavItem
      expanded={isFull}
      icon={<FaChartLine />}
      label="Usage"
      href={`/o/${orgId}/usage`}
    />
  </NavGroup>
)
